.logoIntro {
  @apply text-[#af9351] w-40;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  @apply p-8 md:p-8 xl:p-16 xl:justify-between z-10;
}

.title {
  font-size: clamp(2.5rem, 5vw, 5rem);
  @apply font-light leading-[110%] text-zinc-100;
}

.scrollWrapper {
  @apply mt-8 md:mt-8 xl:mt-16 -mx-8 px-8;
  @apply overflow-scroll snap-mandatory snap-x;
}

.scrollContainter {
  @apply w-fit flex gap-4 xl:gap-10;
}

.skill {
  @apply flex flex-col justify-between;
  @apply backdrop-blur-lg bg-zinc-500 bg-opacity-5;
  @apply p-6 md:p-6 xl:p-8;
  @apply w-[80vw] xl:w-1/3 snap-center;
}

.footer {
  @apply flex-1 flex flex-col justify-between xl:justify-end xl:flex-row xl:items-end;
  
  .contactDetails {
    @apply flex flex-col xl:flex-row xl:gap-16;
  }
  
  .logo {
    @apply text-[#af9351] w-24 ml-auto;
  }
}

a {
  display: inline-block;
  position: relative;
  width: fit-content;
   
  &, *, &::before, &::after {
    transition: all .3s ease;
  }
  
  &::after {
    content: ' ';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 110%;
    transform-origin: center;
    transform: scaleX(0) rotate(0.001deg);
    @apply bg-zinc-100;
  }
  
  &:hover::after {
    width: 100%;
    transform: scaleX(1) rotate(0.001deg);
  }
}